import React from 'react';
import classNames from 'classnames';
import {route, mount, compose} from 'navi';
import {Link} from 'react-navi';
import {Helmet} from 'react-navi-helmet-async';
import History from '@2022-mom-web/components/src/history';
import Schedule from '@2022-mom-web/components/src/schedule';
import Navigation from '@2022-mom-web/components/src/navigation';
import Announcement from '@2022-mom-web/components/src/announcement';
import List from '@2022-mom-web/components/src/list';
import Card from '@2022-mom-web/components/src/card';
import Entry from '@2022-mom-web/components/src/types/entry';
import {MediaBox, MediaBoxes} from "@2022-mom-web/components/src/mediabox";
import history from '../../json/history.json';
import {ReactComponent as Blur} from '../../assets/blur.svg';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as LogoTop} from '../../assets/logo_top.svg';
import {ReactComponent as Logo2021} from '../../assets/logo_2021.svg';
import {ReactComponent as CanCam} from '../../assets/cancam.svg';
import {ReactComponent as Datetime} from "../../assets/datetime.svg";
import {ReactComponent as ACNAL} from "../../assets/ACNAL_MANARA_logo.svg";
import loadingImage from '../../assets/loading.png';
import {list} from '../../json/entry.json';

export default compose(
  mount({
    '/': route({
      getView: ({context}) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url"/>
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image"/>
          </Helmet>
          <App/>
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);

const App = () => {
  return (
    <div id="top">
      <section className="hero is-fullheight is-black">
        <div className="hero-cover">
          <figure className="blur">
            <Blur/>
          </figure>
        </div>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-spaced mb-6">
              <LogoTop style={{maxWidth: 600, maxHeight: 210}}/>
            </h1>
            <p className="subtitle">
              <Datetime/>
            </p>
          </div>
        </div>
        <div className="hero-foot is-relative has-text-centered">
          <Navigation
            additional={
              <li>
                <a href="https://mrofmr.jp/" target="_blank">
                  <span className="icon is-small">
                    <i className="fas fa-external-link-alt" aria-hidden="true"/>
                  </span>
                  <span>MR OF MR</span>
                </a>
              </li>
            }
          />
        </div>
      </section>

      {/*
        Award
      */}
      <section id="award" className="section has-background-gold-stripe">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">AWARD</h1>
            <h2 className="subtitle">受賞者一覧</h2>
          </div>
          {/* グランプリ */}
          <div className="mb-6">
            <h1 className="title has-text-link is-size-3 as-section-title has-text-gold has-text-weight-bold">グランプリ</h1>
            <h2 className="subtitle"/>
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${18}`}
                entry={{
                  "id": 18,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "石川 莉々花",
                  "furigana": "いしかわ りりか",
                  "hometown": "群馬県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "misskeio_2021_1",
                  "instagram": "ririka_ishikawa_",
                  "hobby": "ドラマ鑑賞、生食パン専門店巡り、サッカー",
                  "skill": "上毛かるた",
                  "dream": "アナウンサー",
                  "enthusiasm": "ミス慶應コンテストに続き、自分のことを少しでもみなさんに知っていただけるようがんばります！どうか応援よろしくお願いいたします。",
                  "circle": "放送研究会",
                  "magazine": "CamCam Ray",
                  "birthday": "2001/06/14",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">準グランプリ</h1>
            <h2 className="subtitle"/>
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${17}`}
                entry={
                  {
                    "id": 17,
                    "mysta": "",
                    "showroom": "",
                    "college": "慶應義塾大学",
                    "name": "吉永 光来",
                    "furigana": "よしなが ここ",
                    "hometown": "東京都",
                    "grade": 2,
                    "department": "文学部",
                    "twitter": "misskeio_2021_4",
                    "instagram": "coco_yoshinaga_",
                    "hobby": "映画鑑賞",
                    "skill": "バイオリン、韓国語",
                    "dream": "人に影響を与えること",
                    "enthusiasm": "",
                    "circle": "生協学生委員会",
                    "magazine": "",
                    "birthday": "2001/11/07",
                    "images": [1],
                    "finalist": true,
                  }
                }
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${21}`}
                entry={{
                  "id": 21,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "青野 さくら",
                  "furigana": "あおの さくら",
                  "hometown": "千葉県",
                  "grade": 3,
                  "department": "理工学部",
                  "twitter": "ms_keioygm21_4",
                  "instagram": "sakura_aono_",
                  "hobby": "ダンス",
                  "skill": "書道、アクロバット",
                  "dream": "",
                  "enthusiasm": "私らしく最後まで頑張ります！よろしくお願い致します。",
                  "circle": "ジャズダンスサークルSIG",
                  "magazine": "ViVi",
                  "birthday": "2000/08/10",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">審査員特別賞</h1>
            <h2 className="subtitle"/>
          </div>
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${19}`}
                entry={{
                  "id": 19,
                  "mysta": "",
                  "showroom": "",
                  "college": "関東学院大学",
                  "name": "佐久間 英凜",
                  "furigana": "さくま えりん",
                  "hometown": "神奈川",
                  "grade": 2,
                  "department": "人間共生学部",
                  "twitter": "hiramiss_2021w2",
                  "instagram": "_erin.__",
                  "hobby": "カフェ巡り",
                  "skill": "人を笑顔にすること",
                  "dream": "モデル",
                  "enthusiasm": "自分らしく精一杯頑張ります！",
                  "circle": "",
                  "magazine": "",
                  "birthday": "2002/01/07",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${6}`}
                entry={{
                  "id": 6,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "新藤 利佐",
                  "furigana": "しんどう りさ",
                  "hometown": "神奈川県",
                  "grade": 3,
                  "department": "理工学部",
                  "twitter": "ms_keioygm21_3",
                  "instagram": "risa.shindo.620",
                  "hobby": "美容研究、kpopアイドルのダンス動画を見る",
                  "skill": "クラシックギター、書道",
                  "dream": "化粧品の商品化に携わるお仕事をすること",
                  "enthusiasm": "応援してくださる皆様へ恥ない活動を「自分らしく」行いたいです。その時々の最大限の努力を重ねていけたらと思います。",
                  "circle": "FINESTRA",
                  "magazine": "",
                  "birthday": "2000/06/20",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${34}`}
                entry={{
                  "id": 34,
                  "mysta": "",
                  "showroom": "",
                  "college": "横浜国立大学",
                  "name": "北野 百笑",
                  "furigana": "きたの ももえ",
                  "hometown": "福島県",
                  "grade": 4,
                  "department": "教育学部",
                  "twitter": "msynu2021_03",
                  "instagram": "momo.e_",
                  "hobby": "ファッション・歌うこと",
                  "skill": "歌とピアノ",
                  "dream": "イメージコンサルタント",
                  "enthusiasm": "自分らしく、最後まで楽しく頑張ります！",
                  "circle": "",
                  "magazine": "",
                  "birthday": "1998/05/15",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* ACNAL賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold"><br
                  className="is-hidden-tablet"/>ACNAL賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${7}`}
                entry={{
                  "id": 7,
                  "mysta": "",
                  "showroom": "",
                  "college": "横浜国立大学",
                  "name": "松本 華",
                  "furigana": "まつもと はな",
                  "hometown": "日本",
                  "grade": 3,
                  "department": "教育学部",
                  "twitter": "msynu2021_01",
                  "instagram": "msynu2021_01",
                  "hobby": "ダンス",
                  "skill": "お腹を膨らませる",
                  "dream": "家庭を持つこと",
                  "enthusiasm": "多くの人に自分らしさをお届けできるように、楽しみながら頑張りたいです！",
                  "circle": "ガールズダンスサークル",
                  "magazine": "CanCam",
                  "birthday": "2000/10/26",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* アナトレ賞/テレビ朝日アスク賞/ANNIVERSAIRE賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold"><br
                  className="is-hidden-tablet"/>フジテレビアナトレ賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${39}`}
                entry={{
                  "id": 39,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "豊福 彩理奈",
                  "furigana": "とよふく さりな",
                  "hometown": "東京都",
                  "grade": 2,
                  "department": "商学部",
                  "twitter": "misskeio_2021_2",
                  "instagram": "sarina_toyofuku",
                  "hobby": "ゴルフ、アニメ鑑賞",
                  "skill": "ピアノ、お茶",
                  "dream": "アナウンサー",
                  "enthusiasm": "一生懸命頑張ります！",
                  "circle": "ゴルフ部",
                  "magazine": "Ray, CanCam",
                  "birthday": "2000/11/24",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1
                  className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title has-text-weight-bold">テレビ朝日<br
                  className="is-hidden-tablet"/>アスク賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${26}`}
                entry={{
                  "id": 26,
                  "mysta": "",
                  "showroom": "",
                  "college": "筑紫女学園大学",
                  "name": "冨川 佳恋",
                  "furigana": "とみかわ かれん",
                  "hometown": "長崎県",
                  "grade": 2,
                  "department": "現代社会学部",
                  "twitter": "krn_tmkw",
                  "instagram": "krn_tmkw",
                  "hobby": "お洋服が大好きでよくショッピングに行きます",
                  "skill": "15年間続けている書道です",
                  "dream": "アナウンサーを目指してます！",
                  "enthusiasm": "夢への大きな第一歩を踏み出したく、挑戦することを決意致しました！\n私らしさを大切に精一杯頑張ります！\nよろしくお願い致します！！",
                  "circle": "",
                  "magazine": "CanCam ViVi",
                  "birthday": "2001/04/12",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title "><br className="is-hidden-tablet"/>ANNIVERSAIRE賞
                </h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${33}`}
                entry={{
                  "id": 33,
                  "mysta": "",
                  "showroom": "",
                  "college": "専修大学",
                  "name": "杉山 瑠捺",
                  "furigana": "すぎやま るな",
                  "hometown": "静岡県",
                  "grade": 4,
                  "department": "ネットワーク情報学部",
                  "twitter": "senshu21_ms1",
                  "instagram": "senshu21_ms1",
                  "hobby": "ゴルフ",
                  "skill": "",
                  "dream": "海外で働きたい",
                  "enthusiasm": "精一杯頑張ります！",
                  "circle": "",
                  "magazine": "",
                  "birthday": "1999/09/10",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* CanCam賞/mysta賞/モデルプレス賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">CanCam賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${18}`}
                entry={{
                  "id": 18,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "石川 莉々花",
                  "furigana": "いしかわ りりか",
                  "hometown": "群馬県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "misskeio_2021_1",
                  "instagram": "ririka_ishikawa_",
                  "hobby": "ドラマ鑑賞、生食パン専門店巡り、サッカー",
                  "skill": "上毛かるた",
                  "dream": "アナウンサー",
                  "enthusiasm": "ミス慶應コンテストに続き、自分のことを少しでもみなさんに知っていただけるようがんばります！どうか応援よろしくお願いいたします。",
                  "circle": "放送研究会",
                  "magazine": "CamCam Ray",
                  "birthday": "2001/06/14",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">mysta賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${21}`}
                entry={{
                  "id": 21,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "青野 さくら",
                  "furigana": "あおの さくら",
                  "hometown": "千葉県",
                  "grade": 3,
                  "department": "理工学部",
                  "twitter": "ms_keioygm21_4",
                  "instagram": "sakura_aono_",
                  "hobby": "ダンス",
                  "skill": "書道、アクロバット",
                  "dream": "",
                  "enthusiasm": "私らしく最後まで頑張ります！よろしくお願い致します。",
                  "circle": "ジャズダンスサークルSIG",
                  "magazine": "ViVi",
                  "birthday": "2000/08/10",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">モデルプレス賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${7}`}
                entry={{
                  "id": 7,
                  "mysta": "",
                  "showroom": "",
                  "college": "横浜国立大学",
                  "name": "松本 華",
                  "furigana": "まつもと はな",
                  "hometown": "日本",
                  "grade": 3,
                  "department": "教育学部",
                  "twitter": "msynu2021_01",
                  "instagram": "msynu2021_01",
                  "hobby": "ダンス",
                  "skill": "お腹を膨らませる",
                  "dream": "家庭を持つこと",
                  "enthusiasm": "多くの人に自分らしさをお届けできるように、楽しみながら頑張りたいです！",
                  "circle": "ガールズダンスサークル",
                  "magazine": "CanCam",
                  "birthday": "2000/10/26",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* CanCam賞/mysta賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">ミス学生新聞賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${39}`}
                entry={{
                  "id": 39,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "豊福 彩理奈",
                  "furigana": "とよふく さりな",
                  "hometown": "東京都",
                  "grade": 2,
                  "department": "商学部",
                  "twitter": "misskeio_2021_2",
                  "instagram": "sarina_toyofuku",
                  "hobby": "ゴルフ、アニメ鑑賞",
                  "skill": "ピアノ、お茶",
                  "dream": "アナウンサー",
                  "enthusiasm": "一生懸命頑張ります！",
                  "circle": "ゴルフ部",
                  "magazine": "Ray, CanCam",
                  "birthday": "2000/11/24",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">O0u賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${18}`}
                entry={{
                  "id": 18,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "石川 莉々花",
                  "furigana": "いしかわ りりか",
                  "hometown": "群馬県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "misskeio_2021_1",
                  "instagram": "ririka_ishikawa_",
                  "hobby": "ドラマ鑑賞、生食パン専門店巡り、サッカー",
                  "skill": "上毛かるた",
                  "dream": "アナウンサー",
                  "enthusiasm": "ミス慶應コンテストに続き、自分のことを少しでもみなさんに知っていただけるようがんばります！どうか応援よろしくお願いいたします。",
                  "circle": "放送研究会",
                  "magazine": "CamCam Ray",
                  "birthday": "2001/06/14",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">RAGEBLUE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${31}`}
                entry={{
                  "id": 31,
                  "mysta": "",
                  "showroom": "",
                  "college": "成蹊大学",
                  "name": "田辺 萌夏",
                  "furigana": "たなべ もえか",
                  "hometown": "神奈川県",
                  "grade": 1,
                  "department": "理工学部",
                  "twitter": "missseikei21_03",
                  "instagram": "missseikei2103",
                  "hobby": "踊ること",
                  "skill": "バレエ",
                  "dream": "",
                  "enthusiasm": "周りの方への感謝の気持ちを忘れずに活動していきたいです！",
                  "circle": "",
                  "magazine": "CanCam, Ray",
                  "birthday": "2002/07/07",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>

          {/* Heather賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">Heather賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${18}`}
                entry={{
                  "id": 18,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "石川 莉々花",
                  "furigana": "いしかわ りりか",
                  "hometown": "群馬県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "misskeio_2021_1",
                  "instagram": "ririka_ishikawa_",
                  "hobby": "ドラマ鑑賞、生食パン専門店巡り、サッカー",
                  "skill": "上毛かるた",
                  "dream": "アナウンサー",
                  "enthusiasm": "ミス慶應コンテストに続き、自分のことを少しでもみなさんに知っていただけるようがんばります！どうか応援よろしくお願いいたします。",
                  "circle": "放送研究会",
                  "magazine": "CamCam Ray",
                  "birthday": "2001/06/14",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">Heather賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${7}`}
                entry={{
                  "id": 7,
                  "mysta": "",
                  "showroom": "",
                  "college": "横浜国立大学",
                  "name": "松本 華",
                  "furigana": "まつもと はな",
                  "hometown": "日本",
                  "grade": 3,
                  "department": "教育学部",
                  "twitter": "msynu2021_01",
                  "instagram": "msynu2021_01",
                  "hobby": "ダンス",
                  "skill": "お腹を膨らませる",
                  "dream": "家庭を持つこと",
                  "enthusiasm": "多くの人に自分らしさをお届けできるように、楽しみながら頑張りたいです！",
                  "circle": "ガールズダンスサークル",
                  "magazine": "CanCam",
                  "birthday": "2000/10/26",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>

          {/* CanCam賞/mysta賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">dazzlin賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${34}`}
                entry={{
                  "id": 34,
                  "mysta": "",
                  "showroom": "",
                  "college": "横浜国立大学",
                  "name": "北野 百笑",
                  "furigana": "きたの ももえ",
                  "hometown": "福島県",
                  "grade": 4,
                  "department": "教育学部",
                  "twitter": "msynu2021_03",
                  "instagram": "momo.e_",
                  "hobby": "ファッション・歌うこと",
                  "skill": "歌とピアノ",
                  "dream": "イメージコンサルタント",
                  "enthusiasm": "自分らしく、最後まで楽しく頑張ります！",
                  "circle": "",
                  "magazine": "",
                  "birthday": "1998/05/15",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">ENELSIA賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${18}`}
                entry={{
                  "id": 18,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "石川 莉々花",
                  "furigana": "いしかわ りりか",
                  "hometown": "群馬県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "misskeio_2021_1",
                  "instagram": "ririka_ishikawa_",
                  "hobby": "ドラマ鑑賞、生食パン専門店巡り、サッカー",
                  "skill": "上毛かるた",
                  "dream": "アナウンサー",
                  "enthusiasm": "ミス慶應コンテストに続き、自分のことを少しでもみなさんに知っていただけるようがんばります！どうか応援よろしくお願いいたします。",
                  "circle": "放送研究会",
                  "magazine": "CamCam Ray",
                  "birthday": "2001/06/14",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">BUIK FARM賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${20}`}
                entry={{
                  "id": 20,
                  "mysta": "",
                  "showroom": "",
                  "college": "駒澤大学",
                  "name": "石原 有彩",
                  "furigana": "いしはら ありさ",
                  "hometown": "東京都",
                  "grade": 4,
                  "department": "経済学部",
                  "twitter": "arisa__ishihara",
                  "instagram": "arisa__ishihara",
                  "hobby": "ドライブ",
                  "skill": "阿波踊り",
                  "dream": "幸せな家庭を築くこと！",
                  "enthusiasm": "楽しむことを1番に、表裏の無い活動を通して自分らしく頑張ります！",
                  "circle": "",
                  "magazine": "CanCam",
                  "birthday": "1999/12/13",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>

          {/* La Crème au beurre賞/Now me. by RUNWAY cannel賞/Heather賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">La Crème <br/>au
                  beurre賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${24}`}
                entry={{
                  "id": 24,
                  "mysta": "",
                  "showroom": "",
                  "college": "千葉大学",
                  "name": "星野 愛",
                  "furigana": "ほしの あい",
                  "hometown": "新潟県",
                  "grade": 1,
                  "department": "国際教養学部",
                  "twitter": "chibams2021_no3",
                  "instagram": "chibams2021_no3",
                  "hobby": "チアダンス",
                  "skill": "I字バランス",
                  "dream": "自分のやりたいことに真っ直ぐなかっこいい女性になる",
                  "enthusiasm": "もう一回り成長した自分になれるよう、自分らしく頑張ります！",
                  "circle": "BELINDA(ソングリーディング)、アオカラス(環境学生団体)",
                  "magazine": "CanCam",
                  "birthday": "2003/01/12",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title"><br/>アルプロン賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${42}`}
                entry={{
                  "id": 42,
                  "mysta": "",
                  "showroom": "",
                  "college": "松山大学",
                  "name": "一柳 幸穂",
                  "furigana": "いちりゅう さちほ",
                  "hometown": "愛媛県",
                  "grade": 1,
                  "department": "経営学部",
                  "twitter": "mshimecolle21_3",
                  "instagram": "ms_himecolle21_3",
                  "hobby": "メイク・カフェ巡り",
                  "skill": "ソフトテニス",
                  "dream": "探し中ですが、自分の頑張りで見てくれてる人に元気や目標を与えられる人。",
                  "enthusiasm": "応援してくれた方から頂いた準グランプリだから、恩返しがしたくてエントリーしました。今の自分からもっと変われるように努力します！",
                  "circle": "macl (K-POPダンスサークル)",
                  "magazine": "CanCam",
                  "birthday": "2002/07/09",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase"><br/>Bellcida賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${27}`}
                entry={{
                  "id": 27,
                  "mysta": "",
                  "showroom": "",
                  "college": "慶應義塾大学",
                  "name": "手塚 季史子",
                  "furigana": "てづか きしこ",
                  "hometown": "東京都",
                  "grade": 3,
                  "department": "商学部",
                  "twitter": "KishikoTezuka",
                  "instagram": "kishikotezuka",
                  "hobby": "ゴルフ、一眼レフ",
                  "skill": "フィギュアスケート",
                  "dream": "社長",
                  "enthusiasm": "",
                  "circle": "ダッファーズ",
                  "magazine": "ar",
                  "birthday": "2000/02/11",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* MASCODE賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MASCODE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${37}`}
                entry={{
                  "id": 37,
                  "mysta": "",
                  "showroom": "",
                  "college": "日本大学",
                  "name": "岩橋 美蓮",
                  "furigana": "いわはし みはす",
                  "hometown": "埼玉県",
                  "grade": 2,
                  "department": "法学部",
                  "twitter": "Phoenix2021_05",
                  "instagram": "mihasu_i",
                  "hobby": "カフェ巡り、ラーメン屋巡り、漫画を読むこと",
                  "skill": "バク転、ピアノ",
                  "dream": "アナウンサー",
                  "enthusiasm": "ミス日大法に続き、夢を目指して全力で頑張ります。応援宜しくお願い致します！",
                  "circle": "マスコミ研究会、アナウンス研究会",
                  "magazine": "Ray",
                  "birthday": "2002/01/09",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
          {/* MISCOLLE賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISCOLLE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${32}`}
                entry={{
                  "id": 32,
                  "mysta": "",
                  "showroom": "",
                  "college": "日本大学",
                  "name": "瀬下 莉子",
                  "furigana": "せした りこ",
                  "hometown": "神奈川県",
                  "grade": 2,
                  "department": "文理学部",
                  "twitter": "seshita_riko",
                  "instagram": "seshichan_dayo",
                  "hobby": "犬とあそぶ",
                  "skill": "ピアノ　バドミントン",
                  "dream": "",
                  "enthusiasm": "楽しく自分らしく頑張ります！",
                  "circle": "",
                  "magazine": "mini",
                  "birthday": "2000/10/13",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISCOLLE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${13}`}
                entry={{
                  "id": 13,
                  "mysta": "",
                  "showroom": "",
                  "college": "東洋大学",
                  "name": "アハマドメヘラール",
                  "furigana": "あはまどめへらーる",
                  "hometown": "山梨県",
                  "grade": 4,
                  "department": "文学部",
                  "twitter": "toyo_dress21_03",
                  "instagram": "mraaal7",
                  "hobby": "アクセサリー製作",
                  "skill": "ダンス",
                  "dream": "影響力のある人間になること。",
                  "enthusiasm": "挑戦をし続けることが成長する上で大切だと思い出場しました！グランプリ目指して頑張ります❤️\u200D\uD83D\uDD25応援してくれたら嬉しいです\uD83D\uDE0A",
                  "circle": "ダンスサークル",
                  "magazine": "VIVI NYLON 装苑",
                  "birthday": "1999/07/05",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISCOLLE賞</h1>
                <h2 className="subtitle"/>
              </div>
              <Card
                className="is-missofmiss"
                loadingImage={loadingImage}
                href={`/profiles/${4}`}
                entry={{
                  "id": 4,
                  "mysta": "",
                  "showroom": "",
                  "college": "東京理科大学",
                  "name": "辻野 真衣",
                  "furigana": "つじの まい",
                  "hometown": "兵庫県",
                  "grade": 2,
                  "department": "理工学部",
                  "twitter": "tusambassador_2",
                  "instagram": "tusambassador_2",
                  "hobby": "ラーメン・コンビニスイーツ巡り",
                  "skill": "スポーツ、歌うこと",
                  "dream": "見て下さる方々に夢を与えられる人",
                  "enthusiasm": "大学のコンテストでの経験を活かして、2度目のグランプリを獲れるように頑張ります！",
                  "circle": "",
                  "magazine": "Ray CanCam",
                  "birthday": "2000/10/31",
                  "images": [1],
                  "finalist": true,
                }}
              />
            </div>
          </div>
        </div>
      </section>


      {/*
        Entries
      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="mb-6">
            {/*
                        <h1 className="title as-section-title">ENTRIES</h1>
                        <h2 className="subtitle">出場者一覧</h2>
            */}
            <h1 className="title as-section-title">FINALISTS</h1>
            <h2 className="subtitle">ファイナリスト</h2>
          </div>
          <div className="columns is-multiline is-mobile mb-6">
            <List list={list} size={22} finalist>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile')}
                    key={item.id}
                  >
                    <Card
                      className="is-missofmiss"
                      loadingImage={loadingImage}
                      href={`/profiles/${item.id}`}
                      entry={item}
                    />
                  </div>
                ))
              }
            </List>
          </div>
          {
            /*
           <div className="has-text-centered">
            <Link className="button has-crown is-medium" href={'/entries/finalist'}>
              <span>ファイナリスト一覧</span>
            </Link>
          </div>
             */
          }
          <div className="has-text-centered">
            <Link className="button has-crown is-medium" href={'/entries'}>
              <span>他の候補者を見る</span>
            </Link>
          </div>
        </div>
      </section>
      {/*
        Event
      */}
      <section id="event" className="section">
        <div className="container" style={{maxWidth: 780}}>
          <div className="mb-6">
            <h1 className="title as-section-title">Event</h1>
            <h2 className="subtitle">イベント情報</h2>
          </div>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-3">MISS OF MISS CAMPUS
            QUEEN CONTEST 2022 supported by ACNAL</p>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-3">MR OF MR CAMPUS CONTEST
            2022 supported by ACNAL</p>

          <div className="is-margin-bottom-3 mt-6"><h1
            className="title is-gray is-size-6 has-text-weight-bold as-section-title">MC</h1><h2
            className="subtitle"/></div>

          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered"><p
              className="is-size-6 is-margin-bottom-1"><span className="is-size-5 has-text-weight-bold">NON STYLE</span>
            </p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480"><img src="/image/nonstyle.jpg" width="320"/></figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    またMISS OF MISS CAMPUS QUEEN CONTESTという若者達の人生の変わる瞬間に立ち合えて光栄です。 コロナ禍で満足いくような学生時代が送れなかった方も多いと思います。
                    そんな中で、今回エントリーされている方々、キラキラと輝く夢を見させてくれるはずです。 最高の笑顔のサポートが出来るように頑張ります！！（井上裕介）<br/><br/>
                    今年も始まりますMISS OF MISS CAMPUS QUEEN
                    CONTEST。本当にみんな努力に努力を重ね、頂点を目指しているので感動とパワーとかわいさに溢れています。みなさんお楽しみに。僕は井上がこそこそと電話番号渡したりしないかだけを見張っておきます。（石田明）
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered"><p
              className="is-size-6 is-margin-bottom-1"><span className="is-size-5 has-text-weight-bold">鷲見 玲奈</span><br
              className="is-hidden-tablet"/><span className="is-size-7-mobile">（フリーアナウンサー）</span></p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480"><img src="/image/sumi.jpg" width="320"/></figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    今回初めてMCを務めさせて頂きますが、このような素敵な機会を頂きたいへん嬉しく思います。 <br/>
                    MISS OF MISS CAMPUS QUEEN CONTESTは、学生時代から見ていましたが、とても華やかでキラキラしている舞台という印象でした。<br/>
                    今回エントリーされる皆さんの思いをしっかりと受け取りながら、一人一人の個性が輝く瞬間を見届けたいと思います。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="is-margin-bottom-3 mt-6"><h1
            className="title is-gray is-size-6 has-text-weight-bold as-section-title">ゲスト</h1><h2
            className="subtitle"/></div>

          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered"><p
              className="is-size-6 is-margin-bottom-1"><span className="is-size-5 has-text-weight-bold">山賀 琴子</span><br
              className="is-hidden-tablet"/><span className="is-size-7-mobile">（モデル・ブランドディレクター）</span><br/><br
              className="is-hidden-mobile"/><span className="is-size-7-mobile">MISS OF MISS CAMPUS QUEEN CONTEST <br
              className="is-hidden-tablet"/>2016 グランプリ</span><br/><span
              className="is-size-7-mobile">ミス青山コンテスト2015 グランプリ</span></p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480"><img src="/image/guest.jpg" width="320"/></figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">MISS OF MISS CAMPUS QUEEN
                    CONTESTに参加してから5年が経ち、昨年に引き続き、今年もまた携わらせていただくことができとても嬉しく思います。<br/>
                    エントリーされた皆様の気持ちを想うと、今から私も胸が高まってしまいます。出場者の皆様にお会いできることを、そして皆様と素晴らしい時間を共有できることを心より楽しみにしております。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table is-fullwidth as-information mt-6">
            <tbody>
            <tr>
              <th>日時</th>
              <td>2022年3月9日(水) <br/>
                開場：15時15分 開演：16時00分
              </td>
            </tr>
            <tr>
              <th>会場</th>
              <td>
                <a
                  className="has-text-grey-dark"
                  style={{textDecoration: 'underline'}}
                  href="https://www.ex-theater.com/"
                  target="_blank"
                >
                  EX THEATER ROPPONGI
                </a>{' '}
                (東京都港区西麻布1-2-9)
              </td>
            </tr>
            <tr>
              <th>チケット料金</th>
              <td>前売り券：¥3,000- 当日券：¥3,500-<br/>
                （ドリンク代別）
              </td>
            </tr>
            <tr>
              <th>販売期間</th>
              <td>
                2022年2月14日12:00 〜 2022年3月9日15:00<br/>
                <a href="https://eplus.jp/sf/detail/3572600001-P0030001" target="_blank"
                   className="button is-outlined is-link">チケット購入</a>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">主催</th>
              <td>学生団体MARKS / 株式会社エイジ・エンタテインメント</td>
            </tr>
            <tr>
              <th className="is-narrow">企画制作</th>
              <td>
                <a href="https://miscolle.com/" className="has-text-grey-dark"
                   style={{textDecoration: 'underline'}} target="_blank">
                  MISCOLLE
                </a>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">日程</th>
              <td>
                全国予選：2022/2/14(月)～2022/2/20(日)<br/>
                <br/>
                決勝：2022/2/28(月)～2022/3/6(日)<br/>
                <br/>
                表彰式：2022/3/9(水)
              </td>
            </tr>
            <tr>
              <th>特別協賛</th>
              <td>
                <a href="https://acnal.jp/" target="_blank">
                  <figure id="acnal">
                    <ACNAL height={90}/>
                  </figure>
                </a>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">特別協力</th>
              <td>
                <div id="sponsors" className="images">
                  {/*

                     <a href="https://ray-web.jp/" target="_blank">
                    <figure id="ray" className="image mt-0 mb-6">
                      <Ray height={45} />
                    </figure>
                  </a>
                  */}
                  <a href="https://anatore.fujitv.co.jp/" target="_blank">
                    <figure id="anatore" className="image mt-0 mb-5">
                      <img src="/image/anatore.png"/>
                    </figure>
                  </a>
                  <a href="https://www.tv-asahi-ask.co.jp/" target="_blank">
                    <figure id="ask" className="image mt-0 mb-6">
                      <img src="/image/ask.png"/>
                    </figure>
                  </a>
                  <a href="https://cancam.jp/" target="_blank">
                    <figure id="cancam" className="image mt-0 mb-6">
                      <CanCam height={50}/>
                    </figure>
                  </a>
                  <a href="https://nonno.hpplus.jp/" target="_blank">
                    <figure id="nonno" className="image mt-0 mb-6">
                      <img src="/image/nonno.png"/>
                    </figure>
                  </a>
                  <a href="https://www.mensnonno.jp/" target="_blank">
                    <figure id="mensnonno" className="image mt-0 mb-6">
                      <img src="/image/mensnonno.png"/>
                    </figure>
                  </a>
                  <a href="https://mdpr.jp/" target="_blank">
                    <figure id="modelpress" className="image mt-0 mt-6">
                      <img src="/image/modelpress.png"/>
                    </figure>
                  </a>
                </div>
              </td>
            </tr>
            {/*
            <tr>
              <th className="is-narrow">協賛</th>
              <td>
                <div className="images">
                 <a href="https://www.rizeclinic.com/" target="_blank">
                    <figure id="rize" className="image mt-0 mb-6">
                      <img src="/image/rize.png" height={90} />
                    </figure>
                  </a>
                  <a href="https://www.mens-rize.com/" target="_blank">
                    <figure id="mensrize" className="image mt-0 mb-6">
                      <img src="/image/mensrize.png" height={90} />
                    </figure>
                  </a>
                  <a href="https://www.mysta.tv/" target="_blank">
                    <figure id="mysta" className="image mt-0 mb-6">
                      <img src="/image/mysta.png" height={40} />
                    </figure>
                  </a>
                </div>
              </td>
            </tr>
            */}
            </tbody>
          </table>
        </div>
      </section>
      {/*
        Schedule
      */}
      <section id="schedule" className="section has-background-gradient">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">Schedule</h1>
            <h2 className="subtitle mb-3">日本一のミスキャンパスが決まるまで</h2>
            <p className="is-size-7 has-text-white has-text-centered">
              ※ スケジュールは予告なく変更されることがあります。
            </p>
          </div>
          <Schedule/>
        </div>
      </section>
      {/*
        Media
      */}
      <section id="media" className="section">
        <div className="container" style={{maxWidth: 780}}>
          <div className="mb-6">
            <h1 className="title as-section-title">Media</h1>
            <h2 className="subtitle">掲載メディア</h2>
          </div>
          <MediaBoxes>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://mdpr.jp/news/detail/2462774"
                src="https://img-mdpr.freetls.fastly.net/article/gX5i/wm/gX5it-6CMWh8T0sbyCB7_Z3sicA4TtEzJoErcaOBk6c.jpg"
                title="[モデルプレス]日本一美しい女子大生”が決定 東京大学・神谷明采さんが驚異の4冠＜MISS OF MISS CAMPUS QUEEN CONTEST 2021＞"
                media="modelpress"
                datetext="2021.03.02"
                icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://mdpr.jp/news/detail/2443085"
                src="https://img-mdpr.freetls.fastly.net/article/0GLS/nm/0GLSHAWNmpB4MDt_vlAL8EuSLFZArPlHq71RQIdOvP4.jpg?width=700&disable=upscale&auto=webp"
                title="[モデルプレス]日本一のミスキャンパスを決めるコンテスト、ファイナリスト10名決定＜MISS OF MISS CAMPUS QUEEN CONTEST 2021＞"
                media="modelpress"
                datetext="2021.02.17"
                icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://mdpr.jp/news/detail/2429554"
                src="https://img-mdpr.freetls.fastly.net/article/r3wC/nm/r3wC16kd1fg-_J65xBn2bTimQPpU6KtQgGjxLIZ6Fio.jpg?width=700&disable=upscale&auto=webp"
                title="[モデルプレス]日本一のミスキャンパスを決めるコンテスト、全国予選出場者決定＜MISS OF MISS CAMPUS QUEEN CONTEST 2021＞"
                media="modelpress"
                datetext="2021.02.08"
                icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://mdpr.jp/news/2394445"
                src="https://img-mdpr.freetls.fastly.net/article/PfUk/nm/PfUkwwTLcoRt7-d90MRoPSQq3LEXxCiXy7vIQSc0sto.jpg?width=700&disable=upscale&auto=webp"
                title="[モデルプレス]日本一のミスキャンパスを決めるコンテスト、第1弾出場者発表＜MISS OF MISS CAMPUS QUEEN CONTEST 2021＞"
                media="modelpress"
                datetext="2021.01.15"
                icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{maxWidth: 250}}>
              <MediaBox
                href="https://thetv.jp/news/detail/1017512/"
                src="https://thetv.jp/i/nw/1017512/10151516.jpg?w=615"
                title='[ザ・テレビジョン]全国のミスキャンパスの中から"日本一美しい大学生"を決定する"ミスオブミス"、第一弾出場者発表'
                media="ザ・テレビジョン"
                datetext="2021.01.15"
                icon="https://pbs.twimg.com/profile_images/842775920091975680/7igeN6FE_400x400.jpg"
              />
            </div>
          </MediaBoxes>
        </div>
      </section>
      {/*
        History
      */}
      <section id="history" className="section is-missofmiss">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">History</h1>
            <h2 className="subtitle">ミスオブミスの18年の歴史</h2>
          </div>
          <History json={history}/>
        </div>
      </section>
      <nav className="navbar">
        <div className="columns is-variable is-4 is-mobile is-centered is-vcentered">
          <div className="column is-narrow">
            <figure className="image">
              <Logo2021 width={120}/>
            </figure>
          </div>
          <div className="column is-narrow">
            <a
              href="https://2021.missofmiss.jp"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-size-7-mobile is-black has-text-weight-bold"
            >
              <span>昨年の様子はこちら</span>
              <span className="icon">
                <i className="fas fa-chevron-right"/>
              </span>
            </a>
          </div>
        </div>
      </nav>
      <footer className="footer has-background-black is-padding-bottom-3">
        <div className="content has-text-centered">
          <Logo width={150}/>
          <p className="is-size-7 mt-3">Copyright © 2021 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
        </div>
        <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope"/>
          </span>
          <span>お問い合わせ</span>
        </a>
      </footer>
    </div>
  );
};
