import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import List from '@2022-mom-web/components/src/list';
import Card from '@2022-mom-web/components/src/card';
import Navigation from '@2022-mom-web/components/src/navigation';
import Entry from '@2022-mom-web/components/src/types/entry';
import { Link } from 'react-navi';
import { list } from '../../json/entry.json';
import { ReactComponent as Logo } from '../../assets/missofmiss.svg';
import loadingImage from '../../assets/loading.png';

export default compose(
  mount({
    '/': route({
      getView: ({ context }) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
          </Helmet>
          <App />
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
    '/finalist': route({
      getView: ({ context }) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
          </Helmet>
          <App finalist />
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);

const App = ({ finalist }: { finalist?: boolean }) => {
  const title = finalist ? 'Finalist' : 'Entries';
  const subtitle = finalist ? 'ファイナリスト一覧' : '出場者一覧';
  return (
    <div id="entries">
      <nav className="navbar is-black" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" href="/">
            <Logo />
          </Link>
        </div>
      </nav>
      <Navigation
        additional={
          <li>
            <a href="https://mrofmr.jp/" target="_blank">
              <span className="icon is-small">
                <i className="fas fa-external-link-alt" aria-hidden="true" />
              </span>
              <span>MR OF MR</span>
            </a>
          </li>
        }
      />
      {/* Entries */}
      <section id="entries" className="section">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2>
          </div>
          <div className="columns is-multiline is-mobile mb-6">
            <List list={list} finalist={finalist}>
              {(list: Entry[]) =>
                list.map((item: Entry) => (
                  <div className="column is-3-desktop is-4-tablet is-6-mobile" key={item.id}>
                    <Card
                      className="is-missofmiss"
                      loadingImage={loadingImage}
                      href={`/profiles/${item.id}`}
                      entry={item}
                    />
                  </div>
                ))
              }
            </List>
          </div>
        </div>
      </section>
      <footer className="footer has-background-black is-padding-bottom-3">
        <div className="content has-text-centered">
          <Logo width={150} />
          <p className="is-size-7 mt-3">Copyright © 2021 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
        </div>
        <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope" />
          </span>
          <span>お問い合わせ</span>
        </a>
      </footer>
    </div>
  );
};
